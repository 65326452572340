import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { every, filter, find, includes, isArray, isString, map, some } from 'lodash';
import BodiesFilter from './components/filters/BodiesFilter';
import { coreTypes } from 'dealer-website-components';
import FuelFilter from './components/filters/FuelFilter';
import MakesFilter from './components/filters/MakesFilter';
import ModelsFilter from './components/filters/ModelsFilter';
import YearFilter from './components/filters/YearFilter';
import MileageFilter from './components/filters/MileageFilter';
import PowerFilter from './components/filters/PowerFilter';
import GearboxFilter from './components/filters/GearboxFilter';
import DoorsFilter from './components/filters/DoorsFilter';
import SeatsFilter from './components/filters/SeatsFilter';
import CategoryFilter from './components/filters/CategoryFilter';
import LabelFilter from './components/filters/LabelFilter';
import DealershipFilter from './components/filters/DealershipFilter';
import VatFilter from './components/filters/VatFilter';
import EquipmentFilter from './components/filters/EquipmentFilter';
import ExteriorColorFilter from './components/filters/ExteriorColorFilter';
import InteriorColorFilter from './components/filters/InteriorColorFilter';
import InteriorUpholsteryFilter from './components/filters/InteriorUpholsteryFilter';
import EmissionNormFilter from './components/filters/EmissionNormFilter';
import CO2Filter from './components/filters/CO2Filter';
import SoldFilter from './components/filters/SoldFilter';
import SearchFilter from './components/filters/SearchFilter';
import SortByFilter from './components/filters/SortByFilter';
import LimitFilter from './components/filters/LimitFilter';
import ShuffleFilter from './components/filters/ShuffleFilter';
import SimilarFilter from './components/filters/SimilarFilter';
import MakePriorityFilter from './components/filters/MakePriorityFilter';
import AvailableFilter from './components/filters/AvailableFilter';

export const FilterDataQuery = `{
  dealerMakes {
    key
    name
    models {
      name
      key
    }
  }
  bodyTypes {
    key
    description
  }
  fuels {
    key
    description
  }
  gearboxes {
    key
    description
  }
  categories {
    key
    description
  }
  labels {
    key
    description
  }
  features {
    key
    description
  }
  exteriorColors {
    key
    description
    colorCode
  }
  interiorColors {
    key
    description
    colorCode
  }
  interiorUpholsteries {
    key
    description
  }
  euroNorms {
    key
    description
  }
  tags {
    key
    description
  }
  dealerships {
    id
    name
  } 
}
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  },
  overlay: { zIndex: 999 },
  overflow: 'visible',
  padding: 0
};

export const hasFilterWithValue = (filters, type, value) => {
  const filter = find(filters, (f) => f.type === type && f.value === value);
  return filter ? true : false;
};

const FiltersModal = ({ filters: filtersFromProps, isOpen, onClose, onCreate, context, availableFilterTypes = [], customFiltersList = [] }) => {
  //const state = useContext(StateContext);
  const [state, setState] = useState({});
  const [filters, setFilters] = useState(filtersFromProps);

  const fetchFilterData = () => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: FilterDataQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const filterData = result && result.data ? result.data : {};
        if (filterData) {
          setState(filterData);
        }
      })
      .catch((err) => {});
  };

  const addFilter = (type, value) => {
    let tmpFilters = [...filters, { type: type, value: value }];

    // Delete double make and make|model combinations
    if (type === coreTypes.FilterTypes.makeModel && value.indexOf('|') > -1) {
      tmpFilters = [...filter(tmpFilters, (f) => f.type !== coreTypes.FilterTypes.makeModel || (f.type === coreTypes.FilterTypes.makeModel && f.value !== value.split('|')[0]))];
    }

    setFilters(tmpFilters);
  };

  const deleteFilter = (type, value) => {
    if (type === coreTypes.FilterTypes.makeModel && value.indexOf('|') === -1) {
      // delete of make, delete also all models
      const toDelete = filter(filters, (f) => (f.type === coreTypes.FilterTypes.makeModel && f.value === value) || (f.type === coreTypes.FilterTypes.makeModel && f.value.indexOf('|') > -1 && f.value.split('|')[0] === value));
      const tmpFilters = filter(filters, (f) => !includes(toDelete, f));
      setFilters(tmpFilters);
    } else if (type && value) {
      setFilters(filter(filters, (f) => f.value !== value));
    } else {
      setFilters(filter(filters, (f) => f.type !== type));
    }
  };

  const updateFilter = (type, value) => {
    setFilters(map(filters, (f) => (f.type === type ? { type: type, value: value } : f)));
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  const allFiltersList = Object.keys(coreTypes.FilterTypes).map((key) => coreTypes.FilterTypes[key]);
  const visibleFiltersList = customFiltersList.length > 0 ? customFiltersList : allFiltersList;

  const isFilterVisible = (filterTypes) => {
    if (isArray(filterTypes)) {
      return (availableFilterTypes.length === 0 || some(filterTypes, (filterType) => includes(availableFilterTypes, filterType))) && every(filterTypes, (filterType) => includes(visibleFiltersList, filterType));
    }

    if (isString(filterTypes)) {
      return (availableFilterTypes.length === 0 || includes(availableFilterTypes, filterTypes)) && includes(visibleFiltersList, filterTypes);
    }

    return false;
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false} appElement={document.getElementById('root') || undefined} style={customStyles} contentLabel='Filters modal'>
      <div className='builder-flex builder-justify-between builder-mb-4 builder-bg-gray-100' style={{ marginLeft: -20, marginRight: -20, marginTop: -20, padding: 20 }}>
        <div className='builder-text-[20px] builder-font-semibold'>Define filters</div>
        <div className='builder-cursor-pointer' onClick={() => onClose(filters)}>
          <i className='fal fa-times builder-text-2xl' />
        </div>
      </div>

      <div className='builder-overflow-x-scroll' style={{ width: 500, maxHeight: 600 }}>
        {isFilterVisible(coreTypes.FilterTypes.search) && (
          <SearchFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.search),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
            filterUpdate={updateFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.makeModel) && (
          <MakesFilter
            filterData={map(state.dealerMakes, (i) => ({
              key: i.key,
              text: i.name,
              models: map(i.models, (m) => ({ key: m.key, text: m.name }))
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.makeModel),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.makeModel) && (
          <ModelsFilter
            filterData={map(state.dealerMakes, (i) => ({
              key: i.key,
              text: i.name,
              models: map(i.models, (m) => ({ key: m.key, text: m.name }))
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.makeModel),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.body) && (
          <BodiesFilter
            filterData={map(state.bodyTypes, (i) => ({
              key: i.key,
              text: i.description
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.body),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.year) && (
          <YearFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.year),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterUpdate={updateFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.fuel) && (
          <FuelFilter
            filterData={map(state.fuels, (i) => ({
              key: i.key,
              text: i.description
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.fuel),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.mileage) && (
          <MileageFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.mileage),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterUpdate={updateFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible([coreTypes.FilterTypes.pk, coreTypes.FilterTypes.kw]) && (
          <PowerFilter filters={filter(filters, (f) => f.type === coreTypes.FilterTypes.pk || f.type === coreTypes.FilterTypes.kw)} filterAdd={addFilter} filterUpdate={updateFilter} filterDelete={deleteFilter} />
        )}

        {isFilterVisible(coreTypes.FilterTypes.transmission) && (
          <GearboxFilter
            filterData={map(state.gearboxes, (i) => ({
              key: i.key,
              text: i.description
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.transmission),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.doorRange) && (
          <DoorsFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.doorsRange),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterUpdate={updateFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.seatsRange) && (
          <SeatsFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.seatsRange),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterUpdate={updateFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.category) && (
          <CategoryFilter
            filterData={map(state.categories, (i) => ({
              key: i.key,
              text: i.description
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.category),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.label) && (
          <LabelFilter
            filterData={map(state.labels, (i) => ({
              key: i.key,
              text: i.description
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.label),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.dealership) && (
          <DealershipFilter
            filterData={map(state.dealerships, (i) => ({
              key: i.id,
              text: i.name
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.dealership),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.vat) && (
          <VatFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.vat),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.available) && (
          <AvailableFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.available),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterUpdate={updateFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.sold) && (
          <SoldFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.sold),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterUpdate={updateFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.equipments) && (
          <EquipmentFilter
            filterData={map(state.equipments, (i) => ({
              key: i.key,
              text: i.description
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.equipments),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.exteriorColor) && (
          <ExteriorColorFilter
            filterData={map(state.exteriorColors, (i) => ({
              key: i.key,
              text: i.description,
              custom: i.colorCode
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.exteriorColor),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.interiorColor) && (
          <InteriorColorFilter
            filterData={map(state.interiorColors, (i) => ({
              key: i.key,
              text: i.description,
              custom: i.colorCode
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.interiorColor),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.interiorUpholstery) && (
          <InteriorUpholsteryFilter
            filterData={map(state.interiorUpholsteries, (i) => ({
              key: i.key,
              text: i.description,
              custom: i.colorCode
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.interiorUpholstery),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.euroNorm) && (
          <EmissionNormFilter
            filterData={map(state.emissionNorms, (i) => ({
              key: i.key,
              text: i.description
            }))}
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.euroNorm),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.co2Range) && (
          <CO2Filter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.co2Range),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterUpdate={updateFilter}
            filterDelete={deleteFilter}
          />
        )}

        {isFilterVisible(coreTypes.FilterTypes.sortBy) && (
          <SortByFilter
            filterValues={map(
              filter(filters, (f) => f.type === coreTypes.FilterTypes.sortBy),
              (b) => b.value
            )}
            filterAdd={addFilter}
            filterDelete={deleteFilter}
            filterUpdate={updateFilter}
          />
        )}

        {isFilterVisible([coreTypes.FilterTypes.limit, coreTypes.FilterTypes.shuffle, coreTypes.FilterTypes.similar, coreTypes.FilterTypes.makePriority]) && (
          <div>
            <div className='mt-4 font-bold'>Custom Options</div>

            {(availableFilterTypes.length === 0 || includes(availableFilterTypes, coreTypes.FilterTypes.limit)) && (
              <LimitFilter
                filterValues={map(
                  filter(filters, (f) => f.type === coreTypes.FilterTypes.limit),
                  (b) => b.value
                )}
                filterAdd={addFilter}
                filterDelete={deleteFilter}
                filterUpdate={updateFilter}
              />
            )}

            {(availableFilterTypes.length === 0 || includes(availableFilterTypes, coreTypes.FilterTypes.shuffle)) && (
              <ShuffleFilter
                filterValues={map(
                  filter(filters, (f) => f.type === coreTypes.FilterTypes.shuffle),
                  (b) => b.value
                )}
                filterAdd={addFilter}
                filterDelete={deleteFilter}
                filterUpdate={updateFilter}
              />
            )}

            {(availableFilterTypes.length === 0 || includes(availableFilterTypes, coreTypes.FilterTypes.similar)) && (
              <SimilarFilter
                filterValues={map(
                  filter(filters, (f) => f.type === coreTypes.FilterTypes.similar),
                  (b) => b.value
                )}
                filterAdd={addFilter}
                filterDelete={deleteFilter}
                filterUpdate={updateFilter}
              />
            )}

            {(availableFilterTypes.length === 0 || includes(availableFilterTypes, coreTypes.FilterTypes.makePriority)) && (
              <MakePriorityFilter
                filterValues={map(
                  filter(filters, (f) => f.type === coreTypes.FilterTypes.makePriority),
                  (b) => b.value
                )}
                filterAdd={addFilter}
                filterDelete={deleteFilter}
                filterUpdate={updateFilter}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
export default FiltersModal;
